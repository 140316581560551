<template>
  <div
    ref="navItem"
    class="md:relative hover:bg-gray-500/10 cursor-pointer rounded-[10px] h-[40px] inline-flex items-center px-4 gap-[10px] font-serif text-[18px]"
  >
    <img :src="icon" class="h-[24px] w-[24px]" />
    <span>{{ value }}</span>

    <div
      v-if="isHovered"
      class="shadow-xl absolute left-2 md:left-auto bottom-0 translate-y-[100%] right-0 font-sans max-w-[calc(100vw-16px)] cursor-default"
    >
      <div class="mt-2 border-2 bg-white border-accent rounded-[20px]">
        <slot />
      </div>
    </div>

    <!-- overlay -->
    <Teleport to="body">
      <Transition name="fade">
        <div
          v-if="isHovered"
          class="fixed inset-0 z-20 w-screen h-screen bg-white/60"
          @click="isHovered = false"
        ></div>
      </Transition>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { useElementHover } from "@vueuse/core";

interface NavItemProps {
  icon: string;
  value: string | number;
}

defineProps<NavItemProps>();

const navItem = ref();
const isHovered = useElementHover(navItem);
</script>
