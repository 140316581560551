import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { toast } from "~/components/toast";
import { getPlanQuery } from "~/domains/payment/queries/useGetPlansQuery";

export default function useAuthHandler() {
  const router = useRouter();
  const token = useCookie("token");
  const authStore = useAuthStore();
  const loading = ref(true);
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async () => {
      const config = useRuntimeConfig();
      const authStore = useAuthStore();
      const client = GetApiClient(
        config.public.apiURL as string,
        token.value ?? "",
      );

      const authRes = await client.auth.$get();

      if (authRes.ok) {
        const data = await authRes.json();

        if (data.user.type !== "user") {
          authStore.logout();
          toast.error("Entre em uma conta de estudante");
          router.push("/");
          return;
        }

        await queryClient.prefetchQuery(getPlanQuery());
        const subjectsRes = await client.student.subjects.$get();
        const subjectData = await subjectsRes.json();

        authStore.login(
          data.user as any,
          data.session as any,
          data.student as any,
        );

        authStore.$patch({
          studentStreak: data.studentStreak ?? {
            streak: 0,
            weeks: []
          },
          totalQuestionsToday: data.studentQuestionsToday,
          subjectsStars: data.subjectsStars,
          subjects: subjectData,
        });

        if (!authStore.student) {
          router.push("/cadastrar?etapa=2");
        }
        loading.value = false;
      } else {
        authStore.logout();
        router.push("/");
      }
    },
  });

  onMounted(async () => {
    authStore.$patch({
      token: token.value ?? "",
    });

    if (!authStore.token) {
      loading.value = false;
      router.push("/");
      return;
    }

    if (authStore.user) {
      if (!authStore.student) {
        router.push("/cadastrar?etapa=2");
        loading.value = false;
        return;
      }
    }

    mutate();
  });

  return {
    loading,
  };
}
