<template>
  <UiDrawer persistent v-model="modalOpened">
    <div v-if="plans" class="p-2 h-[calc(90dvh)] overflow-auto pb-10">
      <div class="max-w-[600px] mx-auto">
        <div class="leading-none mb-1 font-serif text-[24px] text-center flex justify-center items-center">
          <span class="whitespace-nowrap">respostaCerta</span>
          <span class="text-primary ml-2">PREMIUM</span>
          <img src="/images/icons/star.png" class="min-w-[28px] h-[28px] opacity-80 ml-2" />
        </div>
        <p class="text-[18px] text-center">
          Libere todos os recursos da plataforma
        </p>

        <ul class="mt-4 grid gap-2 md:text-[18px] md:font-medium">
          <li v-for="b in benefits" :key="b">✅ {{ b }}</li>
        </ul>

        <div class="mt-4 font-medium">Escolha um plano:</div>

        <div class="mt-2 grid gap-2">
          <div v-for="p in plans" class="relative border-2 cursor-pointer rounded-[14px] p-4 flex items-center"
            @click="plan = p.id" :class="{
              'border-primary text-primary': plan === p.id,
              'border-accent/60': plan !== p.id,
            }">
            <div class="font-bold text-[20px]">✓</div>
            <div class="font-serif text-[24px] ml-2">{{ p.name }}</div>
            <div class="ml-auto text-right text-black">
              <div class="font-bold tracking-wide">{{ p.byMonthFormated }} / mês</div>
              <div class="text-[14px] text-accent">Total de {{ p.totalFormated }}</div>
            </div>

            <div v-if="p.bestPrice" class="absolute right-4 text-white px-4 text-[14px] top-0 -translate-y-1/2 bg-primary rounded-[4px]">
              Melhor preço
            </div>
          </div>
        </div>

        <UiButton @click="goToPayment" size="lg" class="mt-10">
          <div class="flex flex-col">
            <span>Assinar</span>
          </div>
        </UiButton>
      </div>
    </div>
  </UiDrawer>
</template>

<script lang="ts" setup>
import { usePlans } from "~/domains/payment/composables/usePlans";
import { UiDrawer, UiButton } from "../ui";

const { plans, plan, modalOpened, benefits, goToPayment } = usePlans();
</script>
