<template>
  <div class="w-screen" v-if="!loading">
    <UiNavbar />
    <div class="container text-accent py-10">
      <NuxtPage />
    </div>
  </div>
  <div class="flex justify-center items-center h-screen" v-else>
    <UiLoading />
  </div>
  <Toaster />
  <PaymentModal />
</template>

<script type="ts" setup>
import PaymentModal from '~/components/payment/payment-modal.vue';
import { Toaster } from '~/components/toast'
import { UiNavbar, UiLoading } from "~/components/ui";

const { loading } = useAuthHandler()

useHead({
  title: "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
});

</script>

<style lang="sass" scoped>
body
  scrollbar-gutter: stable both-edges
</style>
