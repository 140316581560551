<template>
  <div class="pb-[50px]">
    <nav class="h-[62px] shadow-lg fixed top-0 w-screen z-50 bg-white">
      <div class="container flex items-center h-full">
        <div class="flex items-center" v-if="isInQuestion">
          <div @click="$router.go(-1)">
            <img
              draggable="false"
              class="cursor-pointer"
              src="/images/icons/close-nav.svg"
            />
          </div>
        </div>
        <div
          v-motion
          :initial="{
            opacity: 0,
            x: 4,
          }"
          :enter="{
            opacity: 1,
            x: 0,
          }"
          class="flex items-center"
          v-else-if="notInHome"
        >
          <NuxtLink
            draggable="false"
            to="/inicio"
            @click.stop.prevent="isOnAssinar ? $router.go(-1) : $router.push('/inicio')"
          >
            <img
              draggable="false"
              class="cursor-pointer fill-red-400"
              src="/images/icons/back-nav.svg"
            />
          </NuxtLink>
        </div>
        <div v-else class="flex items-center">
          <img
            v-motion
            :enter="{
              opacity: 1,
              scale: 1,
            }"
            :initial="{
              opacity: 0,
              scale: 0.4,
            }"
            src="~/assets/images/logo.png"
            class="relative z-10 h-8 w-8"
          />
          <span
            v-motion
            :enter="{
              opacity: 1,
              x: 0,
            }"
            :initial="{
              opacity: 0,
              x: -10,
            }"
            class="relative z-0 font-serif ml-2 text-accent text-[22px] hidden md:block"
            >respostaCerta</span
          >
        </div>

        <div v-if="!isOnAssinar" class="flex ml-auto">
          <UiNavitem
            icon="/images/icons/fire.png"
            :value="authStore.studentStreak.streak"
          >
            <StreakPanel />
          </UiNavitem>
          <UiNavitem
            icon="/images/icons/star.png"
            :value="authStore.totalStars"
          >
            <StarsPanel />
          </UiNavitem>
        </div>

        <div v-if="!isOnAssinar" ref="target" class="ml-6 w-[32px] h-[32px] relative">
          <img
            v-if="!menuOpen"
            draggable="false"
            @click="menuOpen = !menuOpen"
            class="cursor-pointer"
            src="/images/icons/open-nav.svg"
          />
          <img
            v-else
            @click="menuOpen = !menuOpen"
            draggable="false"
            class="cursor-pointer"
            src="/images/icons/close-nav.svg"
          />

          <div
            v-if="menuOpen"
            class="absolute bg-white rounded-lg top-10 right-0 z-10 border-2 border-accent whitespace-nowrap flex flex-col *:border-b-2 overflow-hidden *:border-accent"
          >
            <NuxtLink
              class="px-6 py-2 font-medium hover:text-primary"
              :to="item.to"
              v-for="item in menuItems"
              :key="item.title"
              @click="menuOpen = false"
            >
              <span class="mr-2">{{ item.emoji }}</span>
              {{ item.title }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script type="ts" setup>
import { UiNavitem } from "~/components/ui";
import { onClickOutside } from '@vueuse/core'
import StreakPanel from '~/components/home/StreakPanel.vue'
import StarsPanel from '~/components/home/StarsPanel.vue'

const authStore = useAuthStore();

const target = ref(null)
onClickOutside(target, event => menuOpen.value = false)

const menuOpen = ref(false)
const menuItems = [
  {
    title: 'Minha conta',
    emoji: '👤',
    to: '/minha-conta',
  },
  {
    title: 'Ajuda',
    emoji: '❓',
    to: '/ajuda',
  },
  {
    title: 'Sair',
    emoji: '👋',
    to: '/sair',
  },
]

const route = useRoute()

const isInQuestion = computed(() => route.fullPath.includes('/bloco'))
const notInHome = computed(() => route.fullPath !== '/inicio')
const isOnAssinar = computed(() => route.fullPath.includes('/assinar'))
</script>
