<template>
  <div class="w-full">
    <div
      class="border-b-2 rounded-[20px] border-accent px-6 py-4 bg-[#FF9923]/10 flex"
    >
      <div class="md:w-[280px]">
        <div class="font-serif text-[24px] leading-none">
          <span class="text-[#FF1019]">
            <span v-if="authStore.studentStreak.streak">
              {{ authStore.studentStreak.streak }} dia<span
                v-if="authStore.studentStreak.streak > 1"
                >s</span
              >
            </span>
            <span v-else>Comece uma ofensiva</span>
          </span>
          <span
            v-if="authStore.studentStreak.streak"
            class="text-[#FF1019] mt-1"
          >
            de ofensiva
          </span>
        </div>
        <p class="text-[16px] mt-2">
          Responda um bloco de questões por dia para
          <span v-if="authStore.studentStreak.streak">manter</span>
          <span v-else>começar</span> sua ofensiva.
        </p>
      </div>
      <div class="ml-auto pl-4 flex items-center">
        <img
          src="/images/icons/fire.png"
          class="min-w-[60px] h-[60px] opacity-80"
        />
      </div>
    </div>

    <div class="px-6 py-4">
      <div class="flex justify-center gap-4">
        <div
          v-for="(day, i) in authStore.studentStreak.week"
          :key="i"
          class="flex flex-col items-center font-medium text-sm"
          :class="{
            '!font-extrabold text-secondary':
              day.dayOfTheWeek === todayDayOfWeek,
          }"
        >
          <span class="mb-0.5">{{ day.label }}</span>
          <div
            v-if="day.status === 'conquered'"
            class="p-1 bg-secondary/50 rounded-lg border-accent border-2 text-accent"
          >
            <v-icon name="hi-check" :scale="1.1" />
          </div>
          <div
            v-else-if="day.status === 'pending'"
            class="rounded-lg border-accent border-2 p-1 bg-gray-100"
            :class="{
              'border-secondary': day.dayOfTheWeek === todayDayOfWeek,
            }"
          >
            <v-icon class="opacity-0" name="hi-check" :scale="1.1" />
          </div>

          <div
            v-else-if="day.status === 'skipped'"
            class="p-1 bg-[#FF8A00]/50 rounded-lg border-accent border-2 text-accent"
          >
            <v-icon name="hi-solid-minus-sm" :scale="1.1" />
          </div>
          <div
            v-else
            class="p-1 bg-error/50 rounded-lg border-accent border-2 text-accent"
          >
            <v-icon name="md-close" :scale="1.1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";

const authStore = useAuthStore();

const todayDayOfWeek = computed(() => {
  return dayjs().day();
});
</script>
